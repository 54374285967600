import React from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useApi } from "../../core/contexts/ApiProvider";
import { useUser } from "../../core/contexts/UserProvider";
import AppLayout from "../../layouts/AppLayout";

export default function ProfilePage() {
  const api = useApi();
  const { user } = useUser();

  const navigate = useNavigate();
  const handleRemove = async () => {
    if (
      window.confirm(
        `You are about to delete ${user.firstName + " " + user.lastName}`
      ) === true
    ) {
      const response = await api.delete(`/api/users/${user._id}`);
      if (!response.error) {
        alert("User removed");
        localStorage.removeItem("user");
        navigate("/users");
      }
    }
  };

  return (
    <AppLayout>
      <div className="page">
        <div className="pageHeader flex spaceBetween">
          <div className="flex center gap-5">
            {user && (
              <img
                className="userCardImg"
                crossOrigin="anonymous"
                src={`${process.env.REACT_APP_BACKEND_URL}/api/users/profile-picture/${user?._id}`}
                alt=""
                width="100px"
              />
            )}
            <div>
              <h1>{user?.firstName + " " + user?.lastName}</h1>
              <p>{user?.email}</p>
            </div>
          </div>
          <div className="flex center gap-5">
            <Link to="edit">Modifier</Link>
            <button onClick={handleRemove}>Supprimer</button>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}
