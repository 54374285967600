import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import { useApi } from "../../core/contexts/ApiProvider";
import { useUser } from "../../core/contexts/UserProvider";
import AppLayout from "../../layouts/AppLayout";

const SignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
});

export default function EditProfilePage() {
  const api = useApi();
  const { user } = useUser();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      profilePicture: "",
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      email: user?.email || "",
    },
    validationSchema: SignupSchema,
    onSubmit: (values) => {
      let userData = new FormData();
      values.profilePicture &&
        userData.append("profilePicture", values.profilePicture);
      values.firstName && userData.append("firstName", values.firstName);
      values.lastName && userData.append("lastName", values.lastName);
      values.email && userData.append("email", values.email);
      if (!user) {
        api.post(`/api/users`, userData).then((response) => {
          if (!response.error) {
            alert("New account created");
            navigate("/login");
          } else {
            console.log(response);
            setError(`${response.error.error}`);
          }
        });
      } else {
        api.put(`/api/users/${user._id}`, userData).then((response) => {
          if (!response.error) {
            alert("Account updated");
            navigate("/");
          } else {
            console.log(response);
            setError(`${response.error.error}`);
          }
        });
      }
    },
  });

  return (
    <AppLayout>
      <form onSubmit={formik.handleSubmit}>
        <div>{error}</div>
        <div>
          <label htmlFor="profilePicture">
            {user && (
              <img
                className="userCardImg"
                crossOrigin="anonymous"
                src={`${process.env.REACT_APP_BACKEND_URL}/api/users/profile-picture/${user?._id}`}
                alt=""
                width="100px"
              />
            )}
            {formik.values.profilePicture && (
              <img
                crossOrigin="anonymous"
                src={URL.createObjectURL(formik.values.profilePicture)}
                alt=""
                style={{ width: "200px" }}
              />
            )}
            <input
              style={{ display: "none" }}
              id="profilePicture"
              name="profilePicture"
              type="file"
              onChange={(event) => {
                formik.setFieldValue(
                  "profilePicture",
                  event.currentTarget.files[0]
                );
              }}
            />
            <div>Upload</div>
          </label>
        </div>
        <div>
          <label htmlFor="firstName">First Name</label>
          <input
            id="firstName"
            name="firstName"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.firstName}
          />
          {formik.errors.firstName && formik.touched.firstName ? (
            <div>{formik.errors.firstName}</div>
          ) : null}
        </div>
        <div>
          <label htmlFor="lastName">Last Name</label>
          <input
            id="lastName"
            name="lastName"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.lastName}
          />
          {formik.errors.lastName && formik.touched.lastName ? (
            <div>{formik.errors.lastName}</div>
          ) : null}
        </div>
        <div>
          <label htmlFor="email">Email Address</label>
          <input
            id="email"
            name="email"
            type="email"
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          {formik.errors.email && formik.touched.email ? (
            <div>{formik.errors.email}</div>
          ) : null}
        </div>
        <button type="submit">Enregistrer</button>
      </form>
    </AppLayout>
  );
}
