import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Checkbox from "../../components/Checkbox";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import { formatToDate } from "../../core/helpers/utility";
import { useApi } from "../../core/contexts/ApiProvider";
import AppLayout from "../../layouts/AppLayout";
registerLocale("fr", fr);

export default function InvoicesPage() {
  const api = useApi();
  const date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  const [firstDay, setFirstDay] = useState(new Date(y, m, 1));
  const [lastDay, setLastDay] = useState(new Date(y, m + 1, 0));
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const navigate = useNavigate();

  const [invoices, setInvoices] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const query = useLocation().search;

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setSelectedItems(invoices.map((invoice) => invoice._id));
    if (isCheckAll) {
      setSelectedItems([]);
    }
  };

  const handleClick = (e) => {
    const { value, checked } = e.target;
    setSelectedItems([...selectedItems, value]);
    if (!checked) {
      setSelectedItems(selectedItems.filter((item) => item !== value));
    }
  };

  const handleDelete = (invoice) => () => {
    if (window.confirm(`You are about to delete`) === true) {
      api.delete(`/api/invoices/${invoice._id}`).then((response) => {
        if (!response.error) {
          alert("Invoice removed");
          navigate(`/invoices?page=1&firstDay=${firstDay}&lastDay=${lastDay}`);
          window.location.reload();
        }
      });
    }
  };

  const doWithList = (e) => {
    e.preventDefault();
    alert(selectedItems);
    api.post(`/api/invoices/remove_many`, selectedItems).then((response) => {
      if (!response.error) {
        alert("Many remove");
        navigate(`/invoices?page=1&firstDay=${firstDay}&lastDay=${lastDay}`);
        window.location.reload();
      } else {
        console.log(response.error);
      }
    });
  };

  const loadInvoices = ({ query }) => {
    api.get(`/api/invoices/${query}`).then((response) => {
      if (!response.error) {
        setInvoices(response.invoices);
        setPage(response.page);
        setPages(response.pages);
      } else {
        console.log(response.error);
      }
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const fd = new Date(y, m, 1);
    const ld = new Date(y, m + 1, 0);
    setFirstDay(fd);
    setLastDay(ld);
    navigate(`/invoices?page=1&firstDay=${firstDay}&lastDay=${lastDay}`);
  };

  useEffect(() => {
    if (query) {
      loadInvoices({ query });
    } else {
      navigate(`/invoices?page=1&firstDay=${firstDay}&lastDay=${lastDay}`);
    }
  }, [query, navigate, firstDay, lastDay]);

  const searchClicked = (e) => {
    e.preventDefault();
    navigate(`/invoices?page=1&firstDay=${firstDay}&lastDay=${lastDay}`);
  };

  return (
    <AppLayout>
      <div className="app-page">
        {/* Header */}
        <div className="app-page-header">
          <h1>Invoices</h1>
          <div className="btns">
            <Link className="btn" to="/invoices/export">
              Export
            </Link>
            <Link className="btn" to="/invoices/import">
              Import
            </Link>
            <Link className="btn" to="/invoices/0/edit/0">
              Creer
            </Link>
          </div>
        </div>
        <div className="app-page-main">
          <div className="actions">
            <div className="">
              {selectedItems.length > 0 && (
                <div>
                  <button className="btn" onClick={doWithList}>
                    Supprimer la selection
                  </button>
                </div>
              )}
              <button className="btn" onClick={(e) => handleSearch(e)}>
                Actualiser
              </button>
            </div>
            <div>
              <div>
                <DatePicker
                  locale="fr"
                  selected={firstDay}
                  onChange={(date) => setFirstDay(date)}
                />
                <DatePicker
                  locale="fr"
                  selected={lastDay}
                  onChange={(date) => setLastDay(date)}
                />
                <button onClick={searchClicked}>GO</button>
              </div>
            </div>
          </div>
          {invoices?.length > 0 ? (
            <>
              <table className="table">
                <thead>
                  <tr>
                    <th className="first">
                      <Checkbox
                        type="checkbox"
                        name="selectAll"
                        id="selectAll"
                        handleClick={handleSelectAll}
                        isChecked={isCheckAll}
                      />
                    </th>
                    <th>Societe</th>
                    <th>Montant</th>
                    <th>Engager le</th>
                    <th>A payer le</th>
                    <th className="last">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {invoices.map(
                    ({ _id, customer, amount, issued_date, due_date }) => {
                      return (
                        <tr key={_id}>
                          <td className="first">
                            <Checkbox
                              key={_id}
                              type="checkbox"
                              name={customer?.companyName}
                              id={_id}
                              handleClick={handleClick}
                              isChecked={selectedItems.includes(_id)}
                            />
                          </td>
                          <td>
                            <h3>{customer?.companyName}</h3>
                          </td>
                          <td>
                            <p>{amount}</p>
                          </td>
                          <td>
                            <p>{formatToDate(issued_date)}</p>
                          </td>
                          <td>
                            <p>{formatToDate(due_date)}</p>
                          </td>
                          <td className="last">
                            <div className="btns">
                              <Link
                                className="btn"
                                to={`/invoices/${customer?._id}/edit/${_id}`}
                              >
                                Edit
                              </Link>
                              <button
                                className="btn"
                                onClick={handleDelete({
                                  _id,
                                  customer,
                                  amount,
                                  issued_date,
                                  due_date,
                                })}
                              >
                                Delete
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
              {/* Pagination */}
              {pages && (
                <div className="pagination">
                  {[...Array(pages)].map((p, i) => (
                    <a
                      className={`pagination-items ${
                        page === i + 1 ? "active" : ""
                      }`}
                      key={i}
                      href={`/invoices?page=${i + 1}`}
                    >
                      {i + 1}
                    </a>
                  ))}
                </div>
              )}
            </>
          ) : (
            <div id="error-page">
              <h1>Oops!</h1>
              <p>Sorry, an unexpected error has occurred.</p>
              <p>
                <i>No data</i>
              </p>
            </div>
          )}
        </div>
      </div>
    </AppLayout>
  );
}
