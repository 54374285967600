import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import { useApi } from "../core/contexts/ApiProvider";
import queryString from "query-string";

import {
  VictoryChart,
  VictoryTheme,
  VictoryScatter,
  VictoryLabel,
} from "victory";

registerLocale("fr", fr);

export default function MonthlyScatter() {
  const api = useApi();
  const [plot, setPlot] = useState([]);
  const [month, setMonth] = useState(new Date());

  useEffect(() => {
    (async () => {
      const query = queryString.stringify({ month: month });
      const response = await api.get(`/api/expenses/plot?${query}`);
      if (!response.error) {
        setPlot(response);
      }
    })();
  }, [month]);

  return (
    <>
      <h1>Expenses scattered</h1>
      <DatePicker
        locale="fr"
        selected={month}
        onChange={(date) => setMonth(date)}
      />
      <VictoryChart
        theme={VictoryTheme.material}
        height={400}
        width={550}
        domainPadding={40}
      >
        <VictoryScatter
          style={{
            data: { fill: "#01579b", stroke: "#69f0ae", strokeWidth: 2 },
            labels: { fill: "#01579b", fontSize: 10, padding: 8 },
          }}
          bubbleProperty="y"
          maxBubbleSize={15}
          minBubbleSize={5}
          labels={({ datum }) => `$${datum.y} on ${datum.x}th`}
          // labelComponent={VictoryTooltip}
          data={plot}
          domain={{ x: [0, 31] }}
        />
        <VictoryLabel
          textAnchor="middle"
          style={{ fontSize: 14, fill: "#8b8b8b" }}
          x={270}
          y={390}
          text={`day of month`}
        />
        <VictoryLabel
          textAnchor="middle"
          style={{ fontSize: 14, fill: "#8b8b8b" }}
          x={6}
          y={190}
          angle={270}
          text={`Amount ($)`}
        />
      </VictoryChart>
    </>
  );
}
