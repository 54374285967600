import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import SupplierEdit from "../../components/supplier/SupplierEdit";
import { useApi } from "../../core/contexts/ApiProvider";
import AppLayout from "../../layouts/AppLayout";

export default function SupplierEditPage() {
  const api = useApi();
  const { id } = useParams();
  const [supplier, setSupplier] = useState();

  useEffect(() => {
    if (id) {
      api.get(`/api/suppliers/${id}`).then((response) => {
        setSupplier(response);
      });
    }
  }, [id]);

  return (
    <AppLayout>
      <div className="page">
        <div className="header">
          <h1>Nouveau Fournisseur</h1>
          <div className="btns">
            <Link to="/suppliers" className="btn">
              Annuler
            </Link>
          </div>
        </div>
        <SupplierEdit supplier={supplier} />
      </div>
    </AppLayout>
  );
}
