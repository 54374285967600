import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useApi } from "../../core/contexts/ApiProvider";

const SupplierEditSchema = Yup.object().shape({
  companyName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  telephone: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
});

export default function SupplierEdit({ supplier }) {
  const api = useApi();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      companyName: supplier?.companyName || "",
      firstName: supplier?.firstName || "",
      lastName: supplier?.lastName || "",
      telephone: supplier?.telephone || "",
      email: supplier?.email || "",
      logo: null,
    },
    validationSchema: SupplierEditSchema,
    onSubmit: (values) => {
      let supplierData = new FormData();
      values.logo && supplierData.append("logo", values.logo);

      values.companyName &&
        supplierData.append("companyName", values.companyName);
      values.firstName && supplierData.append("firstName", values.firstName);
      values.lastName && supplierData.append("lastName", values.lastName);
      values.telephone && supplierData.append("telephone", values.telephone);
      values.email && supplierData.append("email", values.email);

      if (!supplier) {
        api.post(`/api/suppliers`, supplierData).then((response) => {
          if (!response.error) {
            alert("New supplier created");
            navigate("/suppliers");
          } else {
            console.log(response);
            setError(`${response.error.error}`);
          }
        });
      } else {
        api
          .put(`/api/suppliers/${supplier._id}`, supplierData)
          .then((response) => {
            if (!response.error) {
              alert("Supplier info updated");
              navigate("/suppliers");
            } else {
              console.log(response);
              setError(`${response.error.error}`);
            }
          });
      }
    },
  });

  return (
    <form id="contact-form" onSubmit={formik.handleSubmit}>
      <p>
        <div>{error}</div>
        <div>
          <label htmlFor="logo">
            {formik.values.logo && (
              <img
                crossOrigin="anonymous"
                src={URL.createObjectURL(formik.values.logo)}
                alt=""
                className="avatar"
              />
            )}
            {supplier?._id && !formik.values.logo && (
              <img
                crossOrigin="anonymous"
                src={`${process.env.REACT_APP_BACKEND_URL}/api/suppliers/logo/${supplier?._id}`}
                alt=""
                className="avatar"
              />
            )}
            <input
              style={{ display: "none" }}
              id="logo"
              name="logo"
              type="file"
              onChange={(event) => {
                formik.setFieldValue("logo", event.currentTarget.files[0]);
              }}
            />
            <div>Choisir le logo</div>
          </label>
        </div>
      </p>
      <label htmlFor="companyName">
        <span>Company Name</span>
        <input
          id="companyName"
          name="companyName"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.companyName}
        />
        {formik.errors.companyName && formik.touched.companyName ? (
          <div>{formik.errors.companyName}</div>
        ) : null}
      </label>
      <p>
        <label htmlFor="firstName">
          <span>Name</span>
          <input
            id="firstName"
            name="firstName"
            type="text"
            placeholder="Nom"
            onChange={formik.handleChange}
            value={formik.values.firstName}
          />
          {formik.errors.firstName && formik.touched.firstName ? (
            <div>{formik.errors.firstName}</div>
          ) : null}

          <input
            id="lastName"
            name="lastName"
            type="text"
            placeholder="Prénoms"
            onChange={formik.handleChange}
            value={formik.values.lastName}
          />
          {formik.errors.lastName && formik.touched.lastName ? (
            <div>{formik.errors.lastName}</div>
          ) : null}
        </label>
      </p>
      <label htmlFor="email">
        <span>Email Address</span>
        <input
          id="email"
          name="email"
          type="email"
          onChange={formik.handleChange}
          value={formik.values.email}
        />
        {formik.errors.email && formik.touched.email ? (
          <div>{formik.errors.email}</div>
        ) : null}
      </label>
      <label htmlFor="telephone">
        <span>Telephone</span>
        <input
          id="telephone"
          name="telephone"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.telephone}
        />
        {formik.errors.telephone && formik.touched.telephone ? (
          <div>{formik.errors.telephone}</div>
        ) : null}
      </label>
      <div className="btns">
        <button className="btn" type="submit">
          Enregistrer
        </button>
        <Link className="btn" to="/suppliers">
          Annuler
        </Link>
      </div>
    </form>
  );
}
