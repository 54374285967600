import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { addItem, emptyCart, getCart } from "../../core/helpers/CartHelper";
import Checkbox from "../Checkbox";

export default function ItemsFilter() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedItems, setSelectedItems] = useState([]);
  const [cartItems, setCartItems] = useState(getCart());
  const [items, setItems] = useState([
    {
      _id: "1",
      name: "item1",
      description: "descr1",
      price: 10000,
      quantity: 5,
    },
    {
      _id: "2",
      name: "item2",
      description: "descr2",
      price: 25000,
      quantity: 3,
    },
    {
      _id: "3",
      name: "item3",
      description: "descr3",
      price: 25000,
      quantity: 3,
    },
  ]);

  useEffect(() => {
    console.log(selectedItems);
    console.log(cartItems);
  }, []);

  const isInCart = (id) => {
    const itemInCart = cartItems.filter(
      (cartItem) => cartItem.product._id === id
    );
    return itemInCart.length > 0;
  };

  const handleClick = (e) => {
    const { value, checked } = e.target;
    setSelectedItems([...selectedItems, value]);
    if (!checked) {
      setSelectedItems(selectedItems.filter((item) => item !== value));
    }
  };

  const handleSelection = (e) => {
    e.preventDefault();
    selectedItems.map((selected) => {
      const item = items.find((item) => item._id === selected);
      if (item) {
        addItem(item, () => {
          console.log(`${item.name} added`);
        });
      }
    });
    navigate(searchParams.get("prevState"));
  };

  const handleClearCart = () => {
    emptyCart(() => {
      alert("cart clear!");
      window.location.reload();
    });
  };

  return (
    <div>
      <div>
        <button onClick={handleSelection}>Add selectedItems</button>
        <button onClick={handleClearCart}>Clear cart</button>
      </div>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Designation</th>
            <th>Prix</th>
            <th>Quantité</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr key={index}>
              <td>
                <Checkbox
                  key={item._id}
                  type="checkbox"
                  name={item.name}
                  id={item._id}
                  handleClick={handleClick}
                  isChecked={
                    isInCart(item._id) || selectedItems.includes(item._id)
                  }
                />
              </td>
              <td>{item.name}</td>
              <td>{item.price}</td>
              <td>{item.quantity}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
