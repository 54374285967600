import React from "react";
import * as FileSaver from "file-saver";
import { Link } from "react-router-dom";
import XLSX from "sheetjs-style";
import { useApi } from "../../core/contexts/ApiProvider";
import AppLayout from "../../layouts/AppLayout";

export default function CustomerExportPage() {
  const api = useApi();
  const ExportToExcel = ({ excelData, fileName }) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,charset-UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const loadData = (e) => {
    e.preventDefault();
    api.get(`/api/customers`).then((response) => {
      if (!response.error) {
        ExportToExcel({ excelData: response, fileName: "customer" });
      } else {
        console.log(response.error.message);
      }
    });
  };

  return (
    <AppLayout>
      <div className="container">
        <div className="row">
          <div className="d-flex justify-content-between">
            <h1>Exporter les données</h1>
          </div>
        </div>
        <div className="row">
          <div className="text-center p-4">
            <p className="layout-center">
              Cliquer sur exporter pour avoir vos données sous excel
            </p>
            <div className="layout-center">
              <button className="btn btn-success" onClick={loadData}>
                Exporter
              </button>
              <Link className="btn" to="/customers">
                Annuler
              </Link>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}
