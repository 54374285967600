import React, { useEffect, useState } from "react";
import { fecthYearly } from "../core/api/expense-api";

import { VictoryChart, VictoryTheme, VictoryAxis, VictoryBar } from "victory";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import queryString from "query-string";
import { useApi } from "../core/contexts/ApiProvider";

registerLocale("fr", fr);

export default function ExpenseYearlyBar() {
  const api = useApi();
  const [year, setYear] = useState(new Date());
  const [yearlyExpense, setYearlyExpense] = useState([]);

  const monthStrings = [
    "Jan",
    "Fev",
    "Mar",
    "Avr",
    "Mai",
    "Jui",
    "Jul",
    "Aou",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    (async () => {
      const query = queryString.stringify({ year: year.getFullYear() });
      const response = await api.get(`/api/expenses/yearly?${query}`);
      if (!response.error) {
        setYearlyExpense(response);
      }
    })();
  }, [year]);

  return (
    <div>
      <h1>Yearly Expenses</h1>
      <DatePicker
        locale="fr"
        selected={year}
        onChange={(date) => setYear(date)}
      />
      <VictoryChart
        theme={VictoryTheme.material}
        domainPadding={10}
        height={300}
        width={450}
      >
        <VictoryAxis />
        <VictoryBar
          categories={{
            x: monthStrings,
          }}
          style={{
            data: { fill: "#69f0ae", width: 20 },
            labels: { fill: "#01579b" },
          }}
          data={yearlyExpense.monthTot}
          x={monthStrings["x"]}
          domain={{ x: [0, 13] }}
          labels={({ datum }) => `$${datum.y}`}
        />
      </VictoryChart>
    </div>
  );
}
