import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { upload } from "../../core/api/item-api";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useApi } from "../../core/contexts/ApiProvider";

const uploadSchema = Yup.object().shape({});

export default function ItemImportPage() {
  const api = useApi();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      excelFile: null,
    },
    validationSchema: uploadSchema,
    onSubmit: (values) => {
      if (values.excelFile) {
        let data = new FormData();
        values.excelFile && data.append("excelFile", values.excelFile);

        api.post(`/api/items/uploads`, data).then((response) => {
          if (!response.error) {
            alert("Chargement de données effectuées");
            navigate("/items");
          } else {
            console.log(response);
            setError(`${response.error.error}`);
          }
        });
      } else {
        setError("choisir un fichier");
      }
    },
  });

  return (
    <div className="page">
      <div className="header">
        <h1>Import your item</h1>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div>{error}</div>
        <input
          id="excelFile"
          name="excelFile"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          type="file"
          onChange={(event) => {
            formik.setFieldValue("excelFile", event.currentTarget.files[0]);
          }}
        />

        <button type="submit">View data</button>
        <Link to="/items">Annuler</Link>
      </form>
    </div>
  );
}
