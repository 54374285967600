import React, { useEffect, useState } from "react";
import { fetchAverageCategories } from "../core/api/expense-api";
import { VictoryPie, VictoryTheme, VictoryLabel } from "victory";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import queryString from "query-string";
import { useApi } from "../core/contexts/ApiProvider";

registerLocale("fr", fr);

export default function ExpenseCategoryPie() {
  const api = useApi();
  const [expenses, setExpenses] = useState([]);
  const date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  const [firstDay, setFirstDay] = useState(new Date(y, m, 1));
  const [lastDay, setLastDay] = useState(new Date(y, m + 1, 0));

  useEffect(() => {
    (async () => {
      const query = queryString.stringify({
        firstDay: firstDay,
        lastDay: lastDay,
      });
      const response = await api.get(`/api/expenses/plot?${query}`);
      if (!response.error) {
        setExpenses(response);
      }
    })();
  }, [firstDay, lastDay]);

  return (
    <div>
      <h1>Yearly Expenses</h1>
      <DatePicker
        locale="fr"
        selected={firstDay}
        onChange={(date) => setFirstDay(date)}
      />
      <DatePicker
        locale="fr"
        selected={lastDay}
        onChange={(date) => setLastDay(date)}
      />
      <div style={{ width: 550, margin: "auto" }}>
        <svg viewBox="0 0 320 320">
          <VictoryPie
            standalone={false}
            data={expenses.monthAVG}
            innerRadius={50}
            theme={VictoryTheme.material}
            labelRadius={({ innerRadius }) => innerRadius + 14}
          />
          <VictoryLabel
            textAnchor="middle"
            style={{ fontSize: 14, fill: "#8b8b8b" }}
            x={175}
            y={170}
            text={`Spent \nper category`}
          />
        </svg>
      </div>
    </div>
  );
}
