import React, { useEffect, useState } from "react";
import { useApi } from "../core/contexts/ApiProvider";

export default function ExpenseByCategory() {
  const api = useApi();
  const [expenseCategories, setExpenseCategories] = useState();

  useEffect(() => {
    (async () => {
      const response = await api.get(`/api/expenses/by/category`);
      if (!response.error) {
        setExpenseCategories(response);
      }
    })();
  }, []);

  return (
    <div>
      {expenseCategories?.map((expenseCategory, index) => (
        <div key={index}>
          <h4>{expenseCategory._id}</h4>
          <div>
            {expenseCategory.mergedValues.total &&
            expenseCategory.mergedValues.total -
              expenseCategory.mergedValues.average >
              0
              ? "spent extra"
              : "saved"}
          </div>
          <div>${expenseCategory.mergedValues.average}</div>

          <div>
            $
            {expenseCategory.mergedValues.total
              ? Math.abs(
                  expenseCategory.mergedValues.total -
                    expenseCategory.mergedValues.average
                )
              : expenseCategory.mergedValues.average}
          </div>
        </div>
      ))}
    </div>
  );
}
