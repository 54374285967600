import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { create, fetchSingle, update } from "../../core/api/item-api";
import { useNavigate, useParams } from "react-router";
import { API_URL } from "../../core/config/constants";
import { Link } from "react-router-dom";
import { useApi } from "../../core/contexts/ApiProvider";
import AppLayout from "../../layouts/AppLayout";

const ItemEditSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  price: Yup.string().required("Required"),
  quantity: Yup.string().required("Required"),
});

export default function ItemEditPage() {
  const api = useApi();
  const { id } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [item, setItem] = useState();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      category: item?.category || "",
      name: item?.name || "",
      description: item?.description || "",
      price: item?.price || "",
      quantity: item?.quantity || "",
      photo: null,
    },
    validationSchema: ItemEditSchema,
    onSubmit: (values) => {
      let itemData = new FormData();
      values.photo && itemData.append("photo", values.photo);
      values.category && itemData.append("category", values.category);
      values.name && itemData.append("name", values.name);
      values.description && itemData.append("description", values.description);
      values.price && itemData.append("price", values.price);
      values.quantity && itemData.append("quantity", values.quantity);
      if (!item) {
        api.post(`/api/items`, itemData).then((response) => {
          if (!response.error) {
            formik.resetForm();
            alert("New item created");
            navigate("/items");
          } else {
            console.log(response);
            setError(`${response.error.error}`);
          }
        });
      } else {
        api.put(`/api/items/${item._id}`, itemData).then((response) => {
          if (!response.error) {
            formik.resetForm();
            alert("Customer info updated");
            navigate("/items");
          } else {
            console.log(response);
            setError(`${response.error.error}`);
          }
        });
      }
    },
  });

  useEffect(() => {
    if (id) {
      api.get(`/api/items/${id}`).then((response) => {
        if (!response.error) {
          setItem(response);
        } else {
          console.log(response.error);
        }
      });
    }
  }, [id]);

  return (
    <AppLayout>
      <div className="app-page">
        <div className="app-page-header">
          <h1>Nouveau service & produit</h1>
          <div className="layout-end">
            <Link className="btn" to="/items">
              Annuler
            </Link>
          </div>
        </div>
        <div className="panel">
          <section>
            <form id="contact-form" onSubmit={formik.handleSubmit}>
              <div>{error}</div>
              <div>
                <label htmlFor="photo">
                  {item && !formik.values.logo && (
                    <img
                      crossOrigin="anonymous"
                      src={`${API_URL}/items/photo/${item._id}`}
                      alt=""
                      style={{ width: "200px" }}
                    />
                  )}
                  {formik.values.photo && (
                    <img
                      crossOrigin="anonymous"
                      src={URL.createObjectURL(formik.values.photo)}
                      alt=""
                      style={{ width: "200px" }}
                    />
                  )}
                  <input
                    style={{ display: "none" }}
                    id="photo"
                    name="photo"
                    type="file"
                    onChange={(event) => {
                      formik.setFieldValue(
                        "photo",
                        event.currentTarget.files[0]
                      );
                    }}
                  />
                  <div>Upload</div>
                </label>
              </div>

              <label htmlFor="category">
                <span>Category</span>
                <input
                  id="category"
                  name="category"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.category}
                />
                {formik.errors.category && formik.touched.category ? (
                  <div>{formik.errors.category}</div>
                ) : null}
              </label>

              <label htmlFor="name">
                <span>Name</span>
                <input
                  id="name"
                  name="name"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />
                {formik.errors.name && formik.touched.name ? (
                  <div>{formik.errors.name}</div>
                ) : null}
              </label>

              <label htmlFor="description">
                <span>Description</span>
                <input
                  id="description"
                  name="description"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.description}
                />
                {formik.errors.description && formik.touched.description ? (
                  <div>{formik.errors.description}</div>
                ) : null}
              </label>

              <p>
                <label htmlFor="price">
                  <>
                    <span>Price / Quantity</span>
                    <input
                      id="price"
                      name="price"
                      type="number"
                      placeholder="price"
                      onChange={formik.handleChange}
                      value={formik.values.price}
                    />
                    {formik.errors.price && formik.touched.price ? (
                      <div>{formik.errors.price}</div>
                    ) : null}
                  </>

                  <>
                    <input
                      id="quantity"
                      name="quantity"
                      type="number"
                      placeholder="quantity"
                      onChange={formik.handleChange}
                      value={formik.values.quantity}
                    />
                    {formik.errors.quantity && formik.touched.quantity ? (
                      <div>{formik.errors.quantity}</div>
                    ) : null}
                  </>
                </label>
              </p>

              <div className="layout-end">
                <button type="submit" className="btn">
                  Enregistrer
                </button>
                <Link className="btn" to="/items">
                  Annuler
                </Link>
              </div>
            </form>
          </section>
        </div>
      </div>
    </AppLayout>
  );
}
