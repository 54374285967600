import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router";
// import { create, update } from "../../core/api/customer-api";
import { Link } from "react-router-dom";
import { useApi } from "../../core/contexts/ApiProvider";
import { useFlash } from "../../core/contexts/FlashProvider";
import Avatar from "../Avatar";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import moment from "moment";
registerLocale("fr", fr);

const CustomerEditSchema = Yup.object().shape({
  companyName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
});

export default function CustomerEdit({ customer, lastNumber }) {
  const api = useApi();
  const navigate = useNavigate();
  const flash = useFlash();
  const [users, setUsers] = useState([]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      companyName: customer?.companyName || "",
      firstName: customer?.firstName || "",
      lastName: customer?.lastName || "",
      telephone: customer?.telephone || "",
      email: customer?.email || "",
      serialNumber: lastNumber ? lastNumber : customer?.serialNumber || "",
      ncc: customer?.ncc || "",
      said: customer?.said || "",
      taxType: customer?.taxType || "",
      town: customer?.town || "",
      activity: customer?.activity || "",
      fees: customer?.fees || "",
      enrollment_date: customer
        ? moment(customer?.enrollment_date).toDate()
        : "",
      author: customer?.author._id || "",
      logo: null,
    },
    validationSchema: CustomerEditSchema,
    onSubmit: async (values) => {
      let customerData = new FormData();
      values.logo && customerData.append("logo", values.logo);

      values.companyName &&
        customerData.append("companyName", values.companyName);
      values.firstName && customerData.append("firstName", values.firstName);
      values.lastName && customerData.append("lastName", values.lastName);
      values.telephone && customerData.append("telephone", values.telephone);
      values.email && customerData.append("email", values.email);
      values.serialNumber &&
        customerData.append("serialNumber", values.serialNumber);
      values.ncc && customerData.append("ncc", values.ncc);
      values.said && customerData.append("said", values.said);
      values.taxType && customerData.append("taxType", values.taxType);
      values.town && customerData.append("town", values.town);
      values.activity && customerData.append("activity", values.activity);
      values.fees && customerData.append("fees", values.fees);
      values.enrollment_date &&
        customerData.append("enrollment_date", values.enrollment_date);
      values.author && customerData.append("author", values.author);

      if (!customer) {
        const response = await api.post(`/api/customers`, customerData);
        if (!response.error) {
          flash("New customer created", "success");
          navigate("/customers");
        } else {
          flash(`${response.error.message}`, "danger");
        }
      } else {
        const response = await api.put(
          `/api/customers/${customer._id}`,
          customerData
        );
        if (!response.error) {
          flash("Customer info updated", "success");
          navigate("/customers");
        } else {
          flash(`${response.error.message}`, "danger");
        }
      }
    },
  });

  useEffect(() => {
    (async () => {
      const response = await api.get(`/api/users`);
      if (!response.error) {
        setUsers(response);
      }
    })();
  }, []);

  return (
    <form id="contact-form" onSubmit={formik.handleSubmit}>
      <div className="row mb-3">
        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
          choisir un logo
        </label>
        <div className="col-sm-10">
          <label htmlFor="logo">
            {formik.values.logo && (
              <img
                crossOrigin="anonymous"
                src={URL.createObjectURL(formik.values.logo)}
                alt=""
                className="avatar"
              />
            )}
            {customer?._id && !formik.values.logo && (
              <img
                crossOrigin="anonymous"
                src={`${process.env.REACT_APP_BACKEND_URL}/api/customers/logo/${customer?._id}`}
                alt=""
                className="avatar"
              />
            )}
            {!customer && (
              <div className="avatar">
                {formik.values.firstName
                  ? formik.values.firstName.charAt(0).toUpperCase()
                  : "C"}
              </div>
            )}
            <input
              style={{ display: "none" }}
              id="logo"
              name="logo"
              type="file"
              onChange={(event) => {
                formik.setFieldValue("logo", event.currentTarget.files[0]);
              }}
            />
          </label>
        </div>
      </div>
      <div className="row mb-3">
        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
          Nom complet
        </label>
        <div className="col-sm">
          <input
            id="firstName"
            name="firstName"
            type="text"
            placeholder="Nom"
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.firstName}
          />
          {formik.errors.firstName && formik.touched.firstName ? (
            <div className="text-danger">{formik.errors.firstName}</div>
          ) : null}
        </div>
        <div className="col-sm">
          <input
            id="lastName"
            name="lastName"
            type="text"
            placeholder="Prénoms"
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.lastName}
          />
          {formik.errors.lastName && formik.touched.lastName ? (
            <div className="text-danger">{formik.errors.lastName}</div>
          ) : null}
        </div>
      </div>
      <div className="row mb-3">
        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
          Nom de la société
        </label>
        <div className="col-sm-10">
          <input
            id="companyName"
            name="companyName"
            type="text"
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.companyName}
          />
          {formik.errors.companyName && formik.touched.companyName ? (
            <div className="text-danger">{formik.errors.companyName}</div>
          ) : null}
        </div>
      </div>
      <div className="row mb-3">
        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
          Numéro d'adhésion
        </label>
        <div className="col-sm-10">
          <input
            id="serialNumber"
            name="serialNumber"
            type="text"
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.serialNumber}
          />
          {formik.errors.serialNumber && formik.touched.serialNumber ? (
            <div className="text-danger">{formik.errors.serialNumber}</div>
          ) : null}
        </div>
      </div>
      <div className="row mb-3">
        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
          NCC
        </label>
        <div className="col-sm-10">
          <input
            id="ncc"
            name="ncc"
            type="text"
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.ncc}
          />
          {formik.errors.ncc && formik.touched.ncc ? (
            <div className="text-danger">{formik.errors.ncc}</div>
          ) : null}
        </div>
      </div>
      <div className="row mb-3">
        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
          SAID
        </label>
        <div className="col-sm-10">
          <input
            id="said"
            name="said"
            type="text"
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.said}
          />
          {formik.errors.said && formik.touched.said ? (
            <div className="text-danger">{formik.errors.said}</div>
          ) : null}
        </div>
      </div>
      <div className="row mb-3">
        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
          Régime d'imposition
        </label>
        <div className="col-sm-10">
          <input
            id="taxType"
            name="taxType"
            type="text"
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.taxType}
          />
          {formik.errors.taxType && formik.touched.taxType ? (
            <div className="text-danger">{formik.errors.taxType}</div>
          ) : null}
        </div>
      </div>
      <div className="row mb-3">
        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
          Localisation
        </label>
        <div className="col-sm-10">
          <input
            id="town"
            name="town"
            type="text"
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.town}
          />
          {formik.errors.town && formik.touched.town ? (
            <div className="text-danger">{formik.errors.town}</div>
          ) : null}
        </div>
      </div>
      <div className="row mb-3">
        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
          Activité
        </label>
        <div className="col-sm-10">
          <input
            id="activity"
            name="activity"
            type="text"
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.activity}
          />
          {formik.errors.activity && formik.touched.activity ? (
            <div className="text-danger">{formik.errors.activity}</div>
          ) : null}
        </div>
      </div>
      <div className="row mb-3">
        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
          Téléphone
        </label>
        <div className="col-sm-10">
          <input
            id="telephone"
            name="telephone"
            type="text"
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.telephone}
          />
          {formik.errors.telephone && formik.touched.telephone ? (
            <div className="text-danger">{formik.errors.telephone}</div>
          ) : null}
        </div>
      </div>
      <div className="row mb-3">
        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
          Adresse email
        </label>
        <div className="col-sm-10">
          <input
            id="email"
            name="email"
            type="email"
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          {formik.errors.email && formik.touched.email ? (
            <div className="text-danger">{formik.errors.email}</div>
          ) : null}
        </div>
      </div>
      <div className="row mb-3">
        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
          Montant de cotisation
        </label>
        <div className="col-sm-10">
          <input
            id="fees"
            name="fees"
            type="text"
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.fees}
          />
          {formik.errors.fees && formik.touched.fees ? (
            <div className="text-danger">{formik.errors.fees}</div>
          ) : null}
        </div>
      </div>
      <div className="row mb-3">
        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
          Date d'adhésion
        </label>
        <div className="col-sm-10">
          <DatePicker
            locale="fr"
            className="form-control"
            dateFormat="dd-MM-yyyy"
            selected={formik.values.enrollment_date}
            onChange={(date) => formik.setFieldValue("enrollment_date", date)}
          />
          {formik.errors.enrollment_date && formik.touched.enrollment_date ? (
            <div className="text-danger">{formik.errors.enrollment_date}</div>
          ) : null}
        </div>
      </div>
      <div className="row mb-3">
        <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
          Comptable
        </label>
        <div className="col-sm-10">
          <select
            id="author"
            name="author"
            className="form-control"
            value={formik.values.author}
            onChange={(e) => formik.setFieldValue("author", e.target.value)}
          >
            <option value="">Choisir un comptable</option>
            {users.map((u, i) => (
              <option value={u._id} key={i}>
                {u.firstName + " " + u.lastName}
              </option>
            ))}
          </select>
          {formik.errors.author && formik.touched.author ? (
            <div className="text-danger">{formik.errors.author}</div>
          ) : null}
        </div>
      </div>
      <div className="d-flex sticky-bottom bg-white py-4 justify-content-between">
        <button className="btn btn-success" type="submit">
          Enregistrer
        </button>
        <Link className="btn btn-light" to="/customers">
          Annuler
        </Link>
      </div>
    </form>
  );
}
