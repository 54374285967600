import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router";
import AppLayout from "./layouts/AppLayout";
import EditProfilePage from "./pages/main/EditProfilePage";
import HomePage from "./pages/main/HomePage";
import LoginPage from "./pages/auth/LoginPage";
import ProfilePage from "./pages/main/ProfilePage";
import RegisterPage from "./pages/auth/RegisterPage";
import UsersPage from "./pages/users/UsersPage";
import ExpensesPage from "./pages/expenses/ExpensesPage";
import ExpenseDetailsPage from "./pages/expenses/ExpenseDetailsPage";
import ExpenseEditPage from "./pages/expenses/ExpenseEditPage";
import CustomersPage from "./pages/customers/CustomersPage";
import InvoicesPage from "./pages/invoices/InvoicesPage";
import ItemsPage from "./pages/items/ItemsPage";
import ReportsPage from "./pages/reports/ReportsPage";
import UserDetailsPage from "./pages/users/UserDetailsPage";
import UserEditPage from "./pages/users/UserEditPage";
import CustomerDetailsPage from "./pages/customers/CustomerDetailsPage";
import CustomerEditPage from "./pages/customers/CustomerEditPage";
import ItemEditPage from "./pages/items/ItemEditPage";
import CustomerImportPage from "./pages/customers/CustomerImportPage";
import CustomerExportPage from "./pages/customers/CustomerExportPage";
import InvoiceEditPage from "./pages/invoices/InvoiceEditPage";
import InvoiceDetailsPage from "./pages/invoices/InvoiceDetailsPage";
import InvoiceImportPage from "./pages/invoices/InvoiceImportPage";
import InvoiceExportPage from "./pages/invoices/InvoiceExportPage";
import SuppliersPage from "./pages/suppliers/SuppliersPage";
import SupplierDetailsPage from "./pages/suppliers/SupplierDetailsPage";
import SupplierImportPage from "./pages/suppliers/SupplierImportPage";
import SupplierExportPage from "./pages/suppliers/SupplierExportPage";
import SupplierEditPage from "./pages/suppliers/SupplierEditPage";
import ExpenseImportPage from "./pages/expenses/ExpenseImportPage";
import ExpenseExportPage from "./pages/expenses/ExpenseExportPage";
import ItemImportPage from "./pages/items/ItemImportPage";
import ItemExportPage from "./pages/items/ItemExportPage";
import ItemDetailsPage from "./pages/items/ItemDetailsPage";
import UserImportPage from "./pages/users/UserImportPage";
import UserExportPage from "./pages/users/UserExportPage";
import SettingPage from "./pages/main/SettingPage";
import ItemsFilter from "./components/items/ItemsFilter";

import ApiProvider from "./core/contexts/ApiProvider";
import UserProvider from "./core/contexts/UserProvider";
import FlashProvider from "./core/contexts/FlashProvider";
import FlashMessage from "./core/components/FlashMessage/FlashMessage";
import PublicRoute from "./core/components/PublicRoute";
import PrivateRoute from "./core/components/PrivateRoute";

export default function App() {
  return (
    <ApiProvider>
      <UserProvider>
        <FlashProvider>
          <FlashMessage />
          <Routes>
            <Route
              path="/login"
              element={
                <PublicRoute>
                  <LoginPage />
                </PublicRoute>
              }
            />
            <Route
              path="/register"
              element={
                <PublicRoute>
                  <RegisterPage />
                </PublicRoute>
              }
            />
            <Route
              path="*"
              element={
                <PrivateRoute>
                  <Routes>
                    <Route path="/" element={<HomePage />}></Route>
                    <Route path="/customers">
                      <Route path="" element={<CustomersPage />}></Route>
                      <Route
                        path=":id"
                        element={<CustomerDetailsPage />}
                      ></Route>
                      <Route
                        path=":id/edit"
                        element={<CustomerEditPage />}
                      ></Route>
                      <Route path="new" element={<CustomerEditPage />}></Route>
                      <Route
                        path="import"
                        element={<CustomerImportPage />}
                      ></Route>
                      <Route
                        path="export"
                        element={<CustomerExportPage />}
                      ></Route>
                    </Route>
                    <Route path="/suppliers">
                      <Route path="" element={<SuppliersPage />}></Route>
                      <Route
                        path=":id"
                        element={<SupplierDetailsPage />}
                      ></Route>
                      <Route
                        path=":id/edit"
                        element={<SupplierEditPage />}
                      ></Route>
                      <Route path="new" element={<SupplierEditPage />}></Route>
                      <Route
                        path="import"
                        element={<SupplierImportPage />}
                      ></Route>
                      <Route
                        path="export"
                        element={<SupplierExportPage />}
                      ></Route>
                    </Route>
                    <Route path="/invoices">
                      <Route path="" element={<InvoicesPage />}></Route>
                      <Route
                        path=":id"
                        element={<InvoiceDetailsPage />}
                      ></Route>
                      <Route
                        path=":customerId/edit/:id"
                        element={<InvoiceEditPage />}
                      ></Route>
                      <Route
                        path=":customerId/edit"
                        element={<InvoiceEditPage />}
                      ></Route>
                      <Route
                        path="import"
                        element={<InvoiceImportPage />}
                      ></Route>
                      <Route
                        path="export"
                        element={<InvoiceExportPage />}
                      ></Route>
                    </Route>
                    <Route path="/expenses">
                      <Route path="" element={<ExpensesPage />}></Route>
                      <Route
                        path=":id"
                        element={<ExpenseDetailsPage />}
                      ></Route>
                      <Route
                        path=":supplierId/edit/:id"
                        element={<ExpenseEditPage />}
                      ></Route>
                      <Route path="new" element={<ExpenseEditPage />}></Route>
                      <Route
                        path="import"
                        element={<ExpenseImportPage />}
                      ></Route>
                      <Route
                        path="export"
                        element={<ExpenseExportPage />}
                      ></Route>
                    </Route>
                    <Route path="/items">
                      <Route path="" element={<ItemsPage />}></Route>
                      <Route path="search" element={<ItemsFilter />}></Route>
                      <Route path=":id" element={<ItemDetailsPage />}></Route>
                      <Route path=":id/edit" element={<ItemEditPage />}></Route>
                      <Route path="new" element={<ItemEditPage />}></Route>
                      <Route path="import" element={<ItemImportPage />}></Route>
                      <Route path="export" element={<ItemExportPage />}></Route>
                    </Route>
                    <Route path="/reports" element={<ReportsPage />}></Route>
                    <Route path="/users">
                      <Route path="" element={<UsersPage />}></Route>
                      <Route path=":id" element={<UserDetailsPage />}></Route>
                      <Route path=":id/edit" element={<UserEditPage />}></Route>
                      <Route path="new" element={<UserEditPage />}></Route>
                      <Route path="import" element={<UserImportPage />}></Route>
                      <Route path="export" element={<UserExportPage />}></Route>
                    </Route>
                    <Route path="/profile" element={<ProfilePage />}></Route>
                    <Route path="/setting" element={<SettingPage />}></Route>
                    <Route
                      path="/profile/edit"
                      element={<EditProfilePage />}
                    ></Route>
                  </Routes>
                </PrivateRoute>
              }
            />
          </Routes>
        </FlashProvider>
      </UserProvider>
    </ApiProvider>
  );
}

function RequireAuth({ children }) {
  let auth = localStorage.getItem("user");
  let location = useLocation();

  if (!auth) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}
