import { API_URL } from "../config/constants";
import { FetchWrapper } from "../helpers/FetchWrapper";

const create = async (data) => {
  try {
    const response = await FetchWrapper.post(`${API_URL}/settings`, data);
    return response;
  } catch (error) {
    console.log(error);
  }
};

const fetchSingle = async (userId) => {
  try {
    const response = await FetchWrapper.get(
      `${API_URL}/settings/byuser/${userId}`
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

const update = async (id, data) => {
  try {
    const response = await FetchWrapper.put(`${API_URL}/settings/${id}`, data);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export { create, fetchSingle, update };
