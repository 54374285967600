import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate, useParams } from "react-router-dom";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import moment from "moment";
import { useApi } from "../../core/contexts/ApiProvider";
registerLocale("fr", fr);

const InvoiceSchema = Yup.object().shape({
  customer_companyName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  customer_firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  customer_lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  customer_telephone: Yup.string().required("Required"),
  customer_address: Yup.string().required("Required"),
  customer_email: Yup.string().email("Invalid email").required("Required"),
  title: Yup.string().required("Required"),
  issue_date: Yup.string().required("Required"),
  due_date: Yup.string().required("Required"),
});

const initInvoice = { items: [] };

export default function InvoiceForm({ customer }) {
  const api = useApi();
  const navigate = useNavigate();
  const { id } = useParams();
  const [error, setError] = useState("");
  const [invoice, setInvoice] = useState(initInvoice);
  const [items, setItems] = useState([]);

  const getTotal = () => {
    return invoice?.items?.reduce((a, b) => {
      return a + b?.quantity * b?.price;
    }, 0);
  };

  const handleRemoveItem = (index) => (event) => {
    event.preventDefault();
    const temp = [...invoice.items];
    temp.splice(index, 1);
    setInvoice({ ...invoice, items: temp });
  };

  const handleAddProduct = (event) => {
    event.preventDefault();
    setInvoice((prevState) => ({
      ...prevState,
      items: [
        ...prevState.items,
        { item: {}, name: "", price: 0, quantity: 1 },
      ],
    }));
  };

  const handleChange = (index, e) => {
    const temp = [...invoice.items];
    temp[index][e.target.name] = e.target.value;
    setInvoice({ ...invoice, items: temp });
  };

  const handleUpdateItem = (index, item) => {
    const temp = [...invoice.items];
    temp[index]["item"] = item;
    temp[index]["name"] = item.name;
    temp[index]["price"] = item.price;
    setInvoice({ ...invoice, items: temp });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      customer: customer?._id || "",
      customer_companyName:
        invoice?.customer_companyName || customer?.companyName || "",
      customer_firstName:
        invoice?.customer_firstName || customer?.firstName || "",
      customer_lastName:
        invoice?.customer_lastName || customer?.firstName || "",
      customer_telephone:
        invoice?.customer_telephone || customer?.telephone || "",
      customer_email: invoice?.customer_email || customer?.email || "",
      customer_address: invoice?.customer_address || customer?.address || "",
      number: invoice?.number || "",
      title: invoice?.title || "",
      notes: invoice?.notes || "",
      issue_date: invoice ? moment(invoice?.due_date).toDate() : "",
      due_date: invoice ? moment(invoice?.due_date).toDate() : "",
    },
    validationSchema: InvoiceSchema,
    onSubmit: (values) => {
      if (invoice.items.length > 0) {
        values.items = invoice.items;
        values.amount = getTotal();
        console.log(id);
        if (id && id !== "0") {
          api.put(`/api/invoices/${id}`, values).then((response) => {
            if (!response.error) {
              navigate("/invoices");
            } else {
              setError(response.error);
            }
          });
        } else {
          api.post(`/api/invoices`, values).then((response) => {
            if (!response.error) {
              navigate("/invoices");
            } else {
              setError(response.error);
            }
          });
        }
      } else {
        setError("Veuillez renseigner des produits ou services");
      }
    },
  });

  useEffect(() => {
    api.get(`/api/items`).then((response) => {
      if (!response.error) {
        setItems(response);
      } else {
        console.log(response.error);
      }
    });
  }, []);

  useEffect(() => {
    if (id && id !== "0") {
      api.get(`/api/invoices/${id}`).then((response) => {
        console.log(response);
        setInvoice(response);
      });
    } else {
      setInvoice(initInvoice);
    }
  }, [id]);

  return (
    <div>
      <form id="invoice" onSubmit={formik.handleSubmit}>
        <div className="invoice-actions">
          <Link className="btn" to="/invoices">
            Annuler
          </Link>
          <button type="submit" disabled={invoice?.items?.length === 0}>
            Enregistrer
          </button>
        </div>
        <div>{error}</div>
        <div className="invoice-header">
          {/* Logo */}
          <div className="invoice-company-logo">
            <img src="../../assets/logo.png" />
          </div>
          {/* Info */}
          <div className="invoice-company-info">
            {/* Info conpamy */}
            <h1>Fatihoune S.A.R.L</h1>
            <p>11 BP 1475 Abidjan</p>
            <p>+225 0103123266</p>
          </div>
        </div>

        <div className="invoice-info">
          {/* Info client */}
          <div className="invoice-info-customer">
            <div>
              <div>Nom de la societe</div>
              <input
                type="text"
                id="customer_companyName"
                onChange={formik.handleChange}
                value={formik.values.customer_companyName}
              />
              {formik.errors.customer_companyName &&
              formik.touched.customer_companyName ? (
                <div>{formik.errors.customer_companyName}</div>
              ) : null}
            </div>
            <div>
              <div>Nom du client</div>
              <input
                type="text"
                id="customer_firstName"
                onChange={formik.handleChange}
                value={formik.values.customer_firstName}
              />
              {formik.errors.customer_firstName &&
              formik.touched.customer_firstName ? (
                <div>{formik.errors.customer_firstName}</div>
              ) : null}
            </div>
            <div>
              <div>Prénoms du client</div>
              <input
                type="text"
                id="customer_lastName"
                onChange={formik.handleChange}
                value={formik.values.customer_lastName}
              />
              {formik.errors.customer_lastName &&
              formik.touched.customer_lastName ? (
                <div>{formik.errors.customer_lastName}</div>
              ) : null}
            </div>
            <div>
              <div>Téléphone</div>
              <input
                type="text"
                id="customer_telephone"
                onChange={formik.handleChange}
                value={formik.values.customer_telephone}
              />
              {formik.errors.customer_telephone &&
              formik.touched.customer_telephone ? (
                <div>{formik.errors.customer_telephone}</div>
              ) : null}
            </div>
            <div>
              <div>Email</div>
              <input
                type="email"
                id="customer_email"
                onChange={formik.handleChange}
                value={formik.values.customer_email}
              />
              {formik.errors.customer_email && formik.touched.customer_email ? (
                <div>{formik.errors.customer_email}</div>
              ) : null}
            </div>
            <div>
              <div>Adresse du client</div>
              <textarea
                id="customer_address"
                onChange={formik.handleChange}
                value={formik.values.customer_address}
              ></textarea>
              {formik.errors.customer_address &&
              formik.touched.customer_address ? (
                <div>{formik.errors.customer_address}</div>
              ) : null}
            </div>
          </div>
          {/* Info facture */}
          <div className="invoice-info-bill">
            <div>
              <div>Date de commande</div>
              <DatePicker
                locale="fr"
                dateFormat="dd-MM-yyyy"
                selected={formik.values.issue_date}
                onChange={(date) => formik.setFieldValue("issue_date", date)}
              />
              {formik.errors.issue_date && formik.touched.issue_date ? (
                <div>{formik.errors.issue_date}</div>
              ) : null}
            </div>
            <div>
              <div>Date de paiement</div>
              <DatePicker
                locale="fr"
                dateFormat="dd-MM-yyyy"
                selected={formik.values.due_date}
                onChange={(date) => formik.setFieldValue("due_date", date)}
              />
              {formik.errors.due_date && formik.touched.due_date ? (
                <div>{formik.errors.due_date}</div>
              ) : null}
            </div>
            <div>
              <div>Numero de la facture</div>
              <input
                type="text"
                id="number"
                onChange={formik.handleChange}
                value={formik.values.number}
              />
              {formik.errors.number && formik.touched.number ? (
                <div>{formik.errors.number}</div>
              ) : null}
            </div>
          </div>
          {/* Info facture */}
          <div className="invoice-info-amount">
            <div>
              <h1>${getTotal()} F.CFA</h1>
            </div>
          </div>
        </div>

        <div>
          <input
            type="text"
            placeholder="Entrer un titre"
            id="title"
            onChange={formik.handleChange}
            value={formik.values.title}
          />
          {formik.errors.title && formik.touched.title ? (
            <div>{formik.errors.title}</div>
          ) : null}
        </div>

        <div className="invoice-items">
          <div className="invoice-items-actions">
            <button onClick={handleAddProduct}>Add</button>
          </div>
          <table className="invoice-items-table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Designation</th>
                <th>Prix</th>
                <th>Quantité</th>
                <th>Montant</th>
                <th className="last">Actions</th>
              </tr>
            </thead>
            <tbody>
              {invoice?.items?.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    {!item.item._id ? (
                      <div>
                        <input
                          type="text"
                          name="name"
                          onChange={(e) => handleChange(index, e)}
                          value={item.name}
                        />
                        <div>
                          {items
                            .filter(
                              (f) =>
                                f.name.includes(item.name) || item.name === ""
                            )
                            .map((item, i) => (
                              <div
                                key={i}
                                onClick={(e) => handleUpdateItem(index, item)}
                              >
                                {item.name}
                              </div>
                            ))}
                        </div>
                      </div>
                    ) : (
                      <>{item.name}</>
                    )}
                  </td>
                  <td>{item.price}</td>
                  <td>
                    <input
                      type="number"
                      name="quantity"
                      onChange={(e) => handleChange(index, e)}
                      value={item.quantity}
                    />
                  </td>
                  <td>{item.price * item.quantity} F.CFA</td>
                  <td>
                    <button onClick={handleRemoveItem(index)}>Supprimer</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div>
          <label>
            <div>Notes</div>
            <textarea
              id="notes"
              onChange={formik.handleChange}
              value={formik.values.notes}
            ></textarea>
          </label>
        </div>
      </form>
    </div>
  );
}
