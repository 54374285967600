import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useApi } from "../../core/contexts/ApiProvider";
import AppLayout from "../../layouts/AppLayout";
import { useFlash } from "../../core/contexts/FlashProvider";

const uploadSchema = Yup.object().shape({});

export default function CustomerImportPage() {
  const api = useApi();
  const navigate = useNavigate();
  const flash = useFlash();
  const [users, setUsers] = useState([]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      excelFile: null,
      author: "",
    },
    validationSchema: uploadSchema,
    onSubmit: (values) => {
      if (values.excelFile) {
        let data = new FormData();
        values.excelFile && data.append("excelFile", values.excelFile);
        values.author && data.append("author", values.author);

        api.post(`/api/customers/upload`, data).then((response) => {
          if (!response.error) {
            flash("Chargement de données effectuées", "success");
            navigate("/customers");
          } else {
            console.log(response);
            flash(`${response.error.error}`, "danger");
          }
        });
      } else {
        flash("choisir un fichier", "danger");
      }
    },
  });

  useEffect(() => {
    (async () => {
      const response = await api.get(`/api/users`);
      if (!response.error) {
        setUsers(response);
      }
    })();
  }, []);

  return (
    <AppLayout>
      <div className="container">
        <div className="row">
          <div className="d-flex justify-content-between">
            <h1>Importer les données</h1>
          </div>
        </div>
        <div className="row">
          <form onSubmit={formik.handleSubmit}>
            <div className="row mb-3">
              <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
                Comptable
              </label>
              <div className="col-sm-10">
                <select
                  id="author"
                  name="author"
                  className="form-control"
                  value={formik.values.author}
                  onChange={(e) =>
                    formik.setFieldValue("author", e.target.value)
                  }
                >
                  <option value="">Choisir un comptable</option>
                  {users.map((u, i) => (
                    <option value={u._id} key={i}>
                      {u.firstName + " " + u.lastName}
                    </option>
                  ))}
                </select>
                {formik.errors.author && formik.touched.author ? (
                  <div className="text-danger">{formik.errors.author}</div>
                ) : null}
              </div>
            </div>
            <div className="row mb-4">
              <input
                id="excelFile"
                name="excelFile"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                type="file"
                onChange={(event) => {
                  formik.setFieldValue(
                    "excelFile",
                    event.currentTarget.files[0]
                  );
                }}
              />
            </div>

            <button className="btn btn-success" type="submit">
              Importer
            </button>
            <Link className="btn btn-light" to="/customers">
              Annuler
            </Link>
          </form>
        </div>
      </div>
    </AppLayout>
  );
}
