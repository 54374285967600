import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useApi } from "../../core/contexts/ApiProvider";

export default function CustomerFilter() {
  const api = useApi();
  const { customerId } = useParams();
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    api.get(`/api/customers`).then((response) => {
      if (!response.error) {
        setCustomers(response);
      } else {
        console.log(response.error);
      }
    });
  }, []);

  return (
    <div id="customer_panel">
      <div className="search_panel">
        <input type="text" placeholder="Rechercher" />
      </div>
      <div className="flex-col">
        {customers.map((customer, index) => (
          <NavLink
            className={`${customerId === customer._id ? "active" : undefined}`}
            key={index}
            to={`/invoices/${customer._id}/edit`}
            end
          >
            {customer.firstName + " " + customer.lastName}
          </NavLink>
        ))}
      </div>
    </div>
  );
}
