import React from "react";
import { FiBell, FiSettings } from "react-icons/fi";
import { useNavigate } from "react-router";
import Navigation from "../components/Navigation";
import { useApi } from "../core/contexts/ApiProvider";
import { useUser } from "../core/contexts/UserProvider";

export default function AppLayout({ children }) {
  const { user } = useUser();
  const navigate = useNavigate();
  const api = useApi();

  const handleLogout = async () => {
    await api.post(`/api/auth/logout`).then(() => {
      localStorage.removeItem("user");
      navigate("");
    });
  };

  return (
    <div id="app" className="container-fluid">
      <nav className="navbar bg-body-tertiary">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src="../../images/logo.png" alt="" width="70px" />
          </a>
          <nav>
            <ul className="nav">
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-expanded="false"
                >
                  <FiBell className="icon" />
                </a>
                <ul className="dropdown-menu dropdown-menu-end">
                  <li>
                    <a className="dropdown-item" href="/profile">
                      Mon profile
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/settings">
                      Paramètres
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li onClick={handleLogout}>
                    <a className="dropdown-item" href="#">
                      Déconnexion
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-expanded="false"
                >
                  <img
                    src={`${process.env.REACT_APP_BACKEND_URL}/api/users/profile-picture/${user._id}`}
                    width="25px"
                  />
                </a>
                <ul className="dropdown-menu dropdown-menu-end">
                  <li>
                    <a className="dropdown-item" href="/profile">
                      Mon profile
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/settings">
                      Paramètres
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li onClick={handleLogout}>
                    <a className="dropdown-item" href="#">
                      Déconnexion
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </nav>
      <div className="container-fluid d-flex">
        <div className="d-flex flex-column my-4">
          <Navigation />
        </div>
        <div className="flex-grow-1 my-4">{children}</div>
      </div>
    </div>
  );
}
