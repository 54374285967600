import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import ExpenseForm from "../../components/ExpenseForm";
import SupplierFilter from "../../components/supplier/SupplierFilter";
import { useApi } from "../../core/contexts/ApiProvider";
import AppLayout from "../../layouts/AppLayout";

export default function ExpenseEditPage() {
  const api = useApi();

  const { id, supplierId } = useParams();
  const [supplier, setSupplier] = useState();
  const [expense, setExpense] = useState();

  useEffect(() => {
    if (id && id !== "0") {
      api.get(`/api/expenses/${id}`).then((response) => {
        setExpense(response);
      });
    }
  }, [id]);

  useEffect(() => {
    if (supplierId && supplierId !== "0") {
      api.get(`/api/suppliers/${supplierId}`).then((response) => {
        setSupplier(response);
      });
    } else {
      setSupplier();
    }
  }, [supplierId]);

  return (
    <AppLayout>
      <div className="layout-start">
        <SupplierFilter />
        <ExpenseForm expense={expense} supplier={supplier} />
      </div>
    </AppLayout>
  );
}
