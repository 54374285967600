import React from "react";
import ExpenseByCategory from "../../components/ExpenseByCategory";
import ExpenseCategoryPie from "../../components/ExpenseCategoryPie";
import ExpenseOverview from "../../components/ExpenseOverview";
import ExpenseYearlyBar from "../../components/ExpenseYearlyBar";
import MonthlyScatter from "../../components/MonthlyScatter";
import AppLayout from "../../layouts/AppLayout";

export default function HomePage() {
  return (
    <AppLayout>
      <div className="app-page">
        <div className="app-page-header">
          <h1>Welcome!</h1>
        </div>
        <div className="app-page-main">
          <ExpenseOverview />
          <ExpenseByCategory />
          <MonthlyScatter />
          <ExpenseYearlyBar />
          <ExpenseCategoryPie />
        </div>
      </div>
    </AppLayout>
  );
}
