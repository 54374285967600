import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { fetchSingle } from "../../core/api/supplier-api";
import { API_URL } from "../../core/config/constants";
import AppLayout from "../../layouts/AppLayout";

export default function SupplierDetailsPage() {
  const { id } = useParams();
  const [supplier, setSupplier] = useState();

  useEffect(() => {
    fetchSingle(id).then((response) => {
      if (!response.error) {
        setSupplier(response);
      } else {
        console.log(response.error);
      }
    });
  }, [id]);

  return (
    <AppLayout>
      <div className="page">
        {supplier && (
          <div className="flex spaceBetween">
            <div className="flex center gap-5">
              <img
                className="userCardImg"
                crossOrigin="anonymous"
                src={`${API_URL}/suppliers/logo/${supplier._id}`}
                alt=""
                width="100px"
              />
              <div>
                <h1>{supplier.firstName + " " + supplier.lastName}</h1>
                <p>{supplier.email}</p>
              </div>
            </div>
            <div className="flex center gap-5">
              <Link to="edit">Modifier</Link>
            </div>
          </div>
        )}
      </div>
    </AppLayout>
  );
}
