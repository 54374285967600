import React, { useEffect, useState } from "react";
import { create, fetchSingle, update } from "../../core/api/setting-api";
import { API_URL } from "../../core/config/constants";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useUser } from "../../core/contexts/UserProvider";
import AppLayout from "../../layouts/AppLayout";

const SettingSchema = Yup.object().shape({
  companyName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  currency: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
});

export default function SettingPage() {
  const { user } = useUser();

  const [setting, setSetting] = useState();

  const [error, setError] = useState("");
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      companyName: setting?.companyName || "",
      currency: setting?.currency || "",
      photo: null,
    },
    validationSchema: SettingSchema,
    onSubmit: (values) => {
      let settingData = new FormData();
      values.photo && settingData.append("photo", values.photo);
      values.companyName &&
        settingData.append("companyName", values.companyName);
      values.currency && settingData.append("currency", values.currency);

      if (!setting) {
        create(settingData).then((response) => {
          if (!response.error) {
            formik.resetForm();
            alert("New setting created");
          } else {
            console.log(response);
            setError(`${response.error.error}`);
          }
        });
      } else {
        update(setting._id, settingData).then((response) => {
          if (!response.error) {
            formik.resetForm();
            alert("Setting info updated");
          } else {
            console.log(response);
            setError(`${response.error.error}`);
          }
        });
      }
    },
  });

  return (
    <AppLayout>
      <div className="page">
        <div className="header">
          <h1>Setting</h1>
        </div>
        <div className="main">
          <form id="contact-form" onSubmit={formik.handleSubmit}>
            <div>{error}</div>
            <label htmlFor="photo">
              {setting && !formik.values.photo ? (
                <img
                  className="br-full"
                  crossOrigin="anonymous"
                  src={`${API_URL}/settings/photo/${setting?._id}`}
                  alt=""
                  width="70px"
                />
              ) : formik.values.photo ? (
                <img
                  crossOrigin="anonymous"
                  src={URL.createObjectURL(formik.values.photo)}
                  alt=""
                  style={{ width: "200px" }}
                />
              ) : (
                <img
                  className="br-full"
                  crossOrigin="anonymous"
                  src={`${API_URL}/settings/photo/default`}
                  alt=""
                  width="70px"
                />
              )}
              <input
                style={{ display: "none" }}
                id="photo"
                name="photo"
                type="file"
                onChange={(event) => {
                  formik.setFieldValue("photo", event.currentTarget.files[0]);
                }}
              />
            </label>
            <label htmlFor="companyName">
              <span>Company Name</span>
              <input
                id="companyName"
                name="companyName"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.companyName}
              />
              {formik.errors.companyName && formik.touched.companyName ? (
                <div>{formik.errors.companyName}</div>
              ) : null}
            </label>
            <label htmlFor="currency">
              <span>Currency</span>
              <select
                id="currency"
                name="currency"
                onChange={formik.handleChange}
                value={formik.values.currency}
              >
                <option value="">Choisir une monnaie</option>
                <option value="F.CFA">F.CFA</option>
                <option value="Euro">Euro</option>
                <option value="Dirham">Dirham</option>
              </select>
              {formik.errors.currency && formik.touched.currency ? (
                <div>{formik.errors.currency}</div>
              ) : null}
            </label>
            <div className="layout-end">
              <button className="btn" type="submit">
                Enregistrer
              </button>
              <Link className="btn" to="/">
                Annuler
              </Link>
            </div>
          </form>
        </div>
      </div>
    </AppLayout>
  );
}
