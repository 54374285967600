import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import UserCard from "./UserCard";
import { logout } from "../core/api/auth-api";
import DropDown from "./dropdown/DropDown";
import {
  FiMonitor,
  FiUser,
  FiClipboard,
  FiDollarSign,
  FiTag,
  FiUsers,
  FiPieChart,
} from "react-icons/fi";
import { useUser } from "../core/contexts/UserProvider";

export default function Navigation() {
  const { user } = useUser();

  return (
    <div id="navigation" className="sidebar">
      <nav className="nav flex-column">
        <NavLink
          className={({ isActive }) =>
            isActive ? "nav-link active" : "nav-link"
          }
          to="/"
          end
        >
          <FiMonitor className="icon" />
          <span>Tableau de bord</span>
        </NavLink>

        <NavLink
          className={({ isActive }) =>
            isActive ? "nav-link active" : "nav-link"
          }
          to="/customers"
        >
          <FiUser className="icon" />
          <span>Adhérents</span>
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? "nav-link active" : "nav-link"
          }
          to="/invoices"
        >
          <FiClipboard className="icon" />
          <span>Invoices</span>
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? "nav-link active" : "nav-link"
          }
          to="/suppliers"
        >
          <FiUser className="icon" />
          <span>Fournisseurs</span>
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? "nav-link active" : "nav-link"
          }
          to="/expenses"
        >
          <FiDollarSign className="icon" />
          <span>Expenses</span>
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? "nav-link active" : "nav-link"
          }
          to="/items"
        >
          <FiTag className="icon" />
          <span>Items & Services</span>
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? "nav-link active" : "nav-link"
          }
          to="/users"
        >
          <FiUsers className="icon" />
          <span>Utilisateurs</span>
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? "nav-link active " : "nav-link"
          }
          to="/reports"
        >
          <FiPieChart className="icon" />
          <span>Reports</span>
        </NavLink>
      </nav>
    </div>
  );
}
