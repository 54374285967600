import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import CustomerEdit from "../../components/customer/CustomerEdit";
// import { fetchSingle } from "../../core/api/customer-api";
import { useApi } from "../../core/contexts/ApiProvider";
import AppLayout from "../../layouts/AppLayout";

export default function CustomerEditPage() {
  const api = useApi();
  const { id } = useParams();
  const [customer, setCustomer] = useState();
  const [lastNumber, setLastNumber] = useState(null);

  useEffect(() => {
    if (id) {
      (async () => {
        const response = await api.get(`/api/customers/${id}`);
        if (!response.error) {
          setCustomer(response);
        }
      })();
    }
  }, [id]);

  useEffect(() => {
    if (!id) {
      (async () => {
        const response = await api.get(`/api/customers/lastNumber`);
        if (!response.error) {
          setLastNumber(response);
        }
      })();
    }
  }, [id]);

  return (
    <AppLayout>
      <div className="container">
        <div className="row">
          <div className="d-flex justify-content-between">
            <h1>Fiche d'adhésion</h1>
          </div>
          <div className="app-page-main">
            <CustomerEdit customer={customer} lastNumber={lastNumber} />
          </div>
        </div>
      </div>
    </AppLayout>
  );
}
