import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { register } from "../core/api/auth-api";
import { useNavigate } from "react-router";
import { update } from "../core/api/user-api";
import { useFlash } from "../core/contexts/FlashProvider";
import { Link } from "react-router-dom";
import { useApi } from "../core/contexts/ApiProvider";

const SignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  password: Yup.string().min(4, "Too Short!").required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
});

export default function Register({ user }) {
  const navigate = useNavigate();
  const flash = useFlash();
  const api = useApi();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      email: user?.email || "",
      password: "",
    },
    validationSchema: SignupSchema,
    onSubmit: async (values) => {
      if (!user) {
        const response = await api.post("/api/users", values);
        if (!response.error) {
          flash("Nouvel utilisateur crée avec success", "success");
          navigate("/users");
        } else {
          flash(`${response.message.error}`, "danger");
        }
      } else {
        const response = await api.put(`/api/users/${user._id}`, values);
        if (!response.error) {
          flash("Compte modifié", "success");
          navigate("/users");
        } else {
          console.log(response);
          flash(`${response.error.error}`, "danger");
        }
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="row mb-3">
        <label htmlFor="firstName" className="col-sm-2 col-form-label">
          Nom
        </label>
        <div className="col-sm-10">
          <input
            id="firstName"
            name="firstName"
            type="text"
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.firstName}
          />
          {formik.errors.firstName && formik.touched.firstName ? (
            <div className="text-danger">{formik.errors.firstName}</div>
          ) : null}
        </div>
      </div>
      <div className="row mb-3">
        <label htmlFor="lastName" className="col-sm-2 col-form-label">
          Prénoms
        </label>
        <div className="col-sm-10">
          <input
            id="lastName"
            name="lastName"
            type="text"
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.lastName}
          />
          {formik.errors.lastName && formik.touched.lastName ? (
            <div className="text-danger">{formik.errors.lastName}</div>
          ) : null}
        </div>
      </div>
      <div className="row mb-3">
        <label htmlFor="email" className="col-sm-2 col-form-label">
          Adresse Email
        </label>
        <div className="col-sm-10">
          <input
            id="email"
            name="email"
            type="email"
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          {formik.errors.email && formik.touched.email ? (
            <div className="text-danger">{formik.errors.email}</div>
          ) : null}
        </div>
      </div>
      <div className="row mb-3">
        <label htmlFor="password" className="col-sm-2 col-form-label">
          Mot de passe
        </label>
        <div className="col-sm-10">
          <input
            id="password"
            name="password"
            type="password"
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.password}
          />
          {formik.errors.password && formik.touched.password ? (
            <div className="text-danger">{formik.errors.password}</div>
          ) : null}
        </div>
      </div>
      <div className="d-flex sticky-bottom bg-white py-4 justify-content-between">
        <button className="btn btn-success" type="submit">
          Enregistrer
        </button>
        <Link className="btn btn-light" to="/users">
          Annuler
        </Link>
      </div>
    </form>
  );
}
