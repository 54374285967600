import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { remove } from "../core/api/user-api";
import { API_URL } from "../core/config/constants";
export default function Profile({ user }) {
  const navigate = useNavigate();
  const handleRemove = () => {
    if (
      window.confirm(
        `You are about to delete ${user.firstName + " " + user.lastName}`
      ) === true
    ) {
      remove(user._id).then((response) => {
        if (!response.error) {
          alert("User removed");
          localStorage.removeItem("user");
          navigate("/users");
        }
      });
    }
  };
  return (
    <>
      {user && (
        <div className="flex spaceBetween">
          <div className="flex center gap-5">
            <img
              className="userCardImg"
              crossOrigin="anonymous"
              src={`${API_URL}/users/photo/${user._id}`}
              alt=""
              width="100px"
            />
            <div>
              <h1>{user.firstName + " " + user.lastName}</h1>
              <p>{user.email}</p>
            </div>
          </div>
          <div className="flex center gap-5">
            <Link to="edit">Modifier</Link>
            <button onClick={handleRemove}>Supprimer</button>
          </div>
        </div>
      )}
    </>
  );
}
