import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { fetchAll } from "../../core/api/supplier-api";
import { useApi } from "../../core/contexts/ApiProvider";

export default function SupplierFilter() {
  const api = useApi();
  const { supplierId } = useParams();
  const [suppliers, setSuppliers] = useState([]);

  useEffect(() => {
    api.get(`/api/suppliers`).then((response) => {
      if (!response.error) {
        setSuppliers(response);
      } else {
        console.log(response.error);
      }
    });
  }, []);

  return (
    <div id="supplier_panel">
      <div className="search_panel">
        <input type="text" placeholder="Rechercher" />
      </div>
      <div className="flex-col">
        {suppliers.map((supplier, index) => (
          <NavLink
            className={`${supplierId === supplier._id ? "active" : undefined}`}
            key={index}
            to={`/expenses/${supplier._id}/edit/0`}
            end
          >
            {supplier.firstName + " " + supplier.lastName}
          </NavLink>
        ))}
      </div>
    </div>
  );
}
