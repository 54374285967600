import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useApi } from "../../core/contexts/ApiProvider";
import AppLayout from "../../layouts/AppLayout";

export default function CustomerDetailsPage() {
  const api = useApi();
  const { id } = useParams();
  const [customer, setCustomer] = useState();

  useEffect(() => {
    api.get(`/api/customers/${id}`).then((response) => {
      if (!response.error) {
        setCustomer(response);
      } else {
        console.log(response.error);
      }
    });
  }, [id]);

  return (
    <AppLayout>
      <div className="page">
        {customer && (
          <div className="flex spaceBetween">
            <div className="flex center gap-5">
              <img
                className="userCardImg"
                crossOrigin="anonymous"
                src={`${process.env.REACT_APP_BACKEND_URL}/customers/logo/${customer._id}`}
                alt=""
                width="100px"
              />
              <div>
                <h1>{customer.firstName + " " + customer.lastName}</h1>
                <p>{customer.email}</p>
              </div>
            </div>
            <div className="flex center gap-5">
              <Link to="edit">Modifier</Link>
            </div>
          </div>
        )}
      </div>
    </AppLayout>
  );
}
