import { API_URL } from "../config/constants";
import { FetchWrapper } from "../helpers/FetchWrapper";
import queryString from "query-string";

const create = async (data) => {
  try {
    const response = await FetchWrapper.post(`${API_URL}/items`, data);
    return response;
  } catch (error) {
    console.log(error);
  }
};

const upload = async (data) => {
  try {
    const response = await FetchWrapper.post(`${API_URL}/items/upload`, data);
    return response;
  } catch (error) {
    console.log(error);
  }
};

const search = async ({ query }) => {
  try {
    const response = await FetchWrapper.get(`${API_URL}/items/search${query}`);
    return response;
  } catch (error) {
    console.log(error);
  }
};

const fetchAll = async () => {
  try {
    const response = await FetchWrapper.get(`${API_URL}/items`);
    return response;
  } catch (error) {
    console.log(error);
  }
};

const fetchSingle = async (id) => {
  try {
    const response = await FetchWrapper.get(`${API_URL}/items/${id}`);
    return response;
  } catch (error) {
    console.log(error);
  }
};

const update = async (id, data) => {
  try {
    const response = await FetchWrapper.put(`${API_URL}/items/${id}`, data);
    return response;
  } catch (error) {
    console.log(error);
  }
};

const remove = async (id) => {
  try {
    const response = await FetchWrapper.remove(`${API_URL}/items/${id}`);
    return response;
  } catch (error) {
    console.log(error);
  }
};

const removeMany = async (data) => {
  try {
    const response = await FetchWrapper.post(
      `${API_URL}/items/remove_many`,
      data
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export {
  create,
  fetchAll,
  search,
  fetchSingle,
  update,
  remove,
  removeMany,
  upload,
};
