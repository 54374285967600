import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Checkbox from "../../components/Checkbox";
import { remove, removeMany, search } from "../../core/api/user-api";
import { useApi } from "../../core/contexts/ApiProvider";
import AppLayout from "../../layouts/AppLayout";

export default function UsersPage() {
  const api = useApi();
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const query = useLocation().search;

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setSelectedItems(users.map((user) => user._id));
    if (isCheckAll) {
      setSelectedItems([]);
    }
  };

  const handleClick = (e) => {
    const { value, checked } = e.target;
    setSelectedItems([...selectedItems, value]);
    if (!checked) {
      setSelectedItems(selectedItems.filter((item) => item !== value));
    }
  };

  const handleDelete = (user) => () => {
    if (window.confirm(`Vous êtes sur le point de supprimer`) === true) {
      api.delete(`/api/users/${user._id}`).then((response) => {
        if (!response.error) {
          alert("Utilisateur supprimé");
          navigate(`/users?page=1`);
          window.location.reload();
        }
      });
    }
  };

  const doWithList = (e) => {
    e.preventDefault();
    alert(selectedItems);
    api.post(`/api/users/remove_many`, selectedItems).then((response) => {
      if (!response.error) {
        alert("Many remove");
        navigate(`/users?page=1`);
        window.location.reload();
      } else {
        console.log(response.error);
      }
    });
  };

  const loadUsers = ({ query }) => {
    api.get(`/api/users/search${query}`).then((response) => {
      console.log(response);
      if (!response.error) {
        setUsers(response.users);
        setPage(response.page);
        setPages(response.pages);
      } else {
        console.log(response.error);
      }
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    navigate(`/users?page=1`);
  };

  useEffect(() => {
    if (query) {
      loadUsers({ query });
    } else {
      navigate(`/users?page=1`);
    }
  }, [query, navigate]);

  return (
    <AppLayout>
      <div className="container">
        {/* Header */}
        <div className="row">
          <div className="d-flex justify-content-between">
            <h1>Utilisateurs</h1>
            <ul className="nav justify-content-end">
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-expanded="false"
                >
                  Autres actions
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <Link className="dropdown-item" to="/users/export">
                      Exporter
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/users/import">
                      Importer
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link className="btn btn-success" to="/users/new">
                  Creer
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="row">
          <div className="d-flex justify-content-between align-items-center my-5">
            <div className="d-flex align-items-center">
              {selectedItems.length > 0 && (
                <div>
                  <button className="btn btn-primary" onClick={doWithList}>
                    Supprimer la selection
                  </button>
                </div>
              )}
              <button
                className="btn btn btn-primary"
                onClick={(e) => handleSearch(e)}
              >
                Actualiser
              </button>
            </div>
            <div>
              <input
                onChange={(e) => navigate(`/users?page=1&q=${e.target.value}`)}
                type="text"
                placeholder="Rechercher..."
              />
            </div>
          </div>
        </div>

        <div className="row">
          {users?.length > 0 ? (
            <>
              <table className="table">
                <thead>
                  <tr>
                    <th className="first">
                      <Checkbox
                        type="checkbox"
                        name="selectAll"
                        id="selectAll"
                        handleClick={handleSelectAll}
                        isChecked={isCheckAll}
                      />
                    </th>
                    <th></th>
                    <th>Nom</th>
                    <th>Prenoms</th>
                    <th>Contact</th>
                    <th className="last">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map(
                    ({ _id, firstName, lastName, telephone, email }) => {
                      return (
                        <tr key={_id}>
                          <td className="first">
                            <Checkbox
                              key={_id}
                              type="checkbox"
                              name={email}
                              id={_id}
                              handleClick={handleClick}
                              isChecked={selectedItems.includes(_id)}
                            />
                          </td>
                          <td>
                            <div className="avatar">
                              {firstName.charAt(0).toUpperCase()}
                            </div>
                          </td>
                          <td>
                            <p>{firstName}</p>
                          </td>
                          <td>
                            <p>{lastName}</p>
                          </td>
                          <td>
                            <p>{email}</p>
                            <p>{telephone}</p>
                          </td>
                          <td className="last">
                            <div className="btns">
                              <Link className="btn" to={`/users/${_id}/edit`}>
                                Modifier
                              </Link>
                              <button
                                className="btn"
                                onClick={handleDelete({
                                  _id,
                                  firstName,
                                  lastName,
                                  telephone,
                                  email,
                                })}
                              >
                                Supprimer
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
              {/* Pagination */}
              <div className="py-5 text-center d-flex justify-content-center">
                {pages && (
                  <div className="pagination">
                    {[...Array(pages)].map((p, i) => (
                      <li className="page-item" key={i}>
                        <a
                          className={`page-link ${
                            page === i + 1 ? "active" : ""
                          }`}
                          href={`/users?page=${i + 1}`}
                        >
                          {i + 1}
                        </a>
                      </li>
                    ))}
                  </div>
                )}
              </div>
            </>
          ) : (
            <div id="error-page">
              <h1>Oops!</h1>
              <p>Sorry, an unexpected error has occurred.</p>
              <p>
                <i>No data</i>
              </p>
            </div>
          )}
        </div>
      </div>
    </AppLayout>
  );
}
