import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../../core/contexts/UserProvider";
import { useFlash } from "../../core/contexts/FlashProvider";
import { useApi } from "../../core/contexts/ApiProvider";

const SigninSchema = Yup.object().shape({
  firstName: Yup.string().required("Le nom est requis"),
  lastName: Yup.string().required("Le prénom(s) est requis"),
  password: Yup.string()
    .min(4, "Doit ètre au moins 4 caractères")
    .required("Le mot de passe est requis"),
  email: Yup.string()
    .email("L'adresse email est invalide")
    .required("L'adresse email est requis"),
});

export default function RegisterPage() {
  const flash = useFlash();
  const api = useApi();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
    validationSchema: SigninSchema,
    onSubmit: async (values) => {
      const response = await api.post("/api/auth/register", values);
      if (!response.error) {
        flash(`Vous vous êtes inscrit avec succès !`, "success");
        navigate("/login");
      } else {
        flash(`${response.error.message}`, "danger");
      }
    },
  });

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-4 offset-sm-4 mt-5">
          <div className="text-center mb-4">
            <img src="../../images/logo.png" alt="" width="100px" />
          </div>
          <div className="card p-4 mb-4">
            <h4 className="mb-3">Enregistrez-vous</h4>
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <label htmlFor="email">Nom</label>
                <input
                  id="firstName"
                  name="firstName"
                  type="text"
                  className="w-100"
                  onChange={formik.handleChange}
                  value={formik.values.firstName}
                  placeholder="Nom"
                />
                {formik.errors.firstName && formik.touched.firstName ? (
                  <div className="text-danger">{formik.errors.firstName}</div>
                ) : null}
              </div>
              <div className="mb-3">
                <label htmlFor="email">Prénoms</label>
                <input
                  id="lastName"
                  name="lastName"
                  type="text"
                  className="w-100"
                  onChange={formik.handleChange}
                  value={formik.values.lastName}
                  placeholder="Prénoms"
                />
                {formik.errors.lastName && formik.touched.lastName ? (
                  <div className="text-danger">{formik.errors.lastName}</div>
                ) : null}
              </div>
              <div className="mb-3">
                <label htmlFor="email">Email adresse</label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  className="w-100"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  placeholder="Email adresse"
                />
                {formik.errors.email && formik.touched.email ? (
                  <div className="text-danger">{formik.errors.email}</div>
                ) : null}
              </div>
              <div className="mb-3">
                <label htmlFor="password">Mot de passe</label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  className="w-100"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  placeholder="Mot de passe"
                />
                {formik.errors.password && formik.touched.password ? (
                  <div className="text-danger">{formik.errors.password}</div>
                ) : null}
              </div>
              <div className="mb-3">
                <button
                  disabled={formik.isSubmitting}
                  className="btn btn-primary w-100"
                  type="submit"
                >
                  {formik.isSubmitting && (
                    <span className="spinner-border spinner-border-sm me-1"></span>
                  )}
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
          <div className="form-separator mb-2">Déjà un compte ?</div>
          <Link className="btn btn-secondary w-100 mb-2" to="/login">
            Connectez-vous
          </Link>
        </div>
      </div>
    </div>
  );
}
