import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import moment from "moment";
import { Link } from "react-router-dom";
import { useApi } from "../core/contexts/ApiProvider";
registerLocale("fr", fr);

const ExpenseSchema = Yup.object().shape({
  title: Yup.string().min(2, "Too Short!").required("Required"),
  amount: Yup.number().required("Required"),
  category: Yup.string().required("Required"),
  incurred_on: Yup.string().required("Required"),
  notes: Yup.string().required("Required"),
});

export default function ExpenseForm({ expense, supplier }) {
  const api = useApi();

  const date = moment(expense?.incurred_on).toDate();
  //   d = date.getDay(),
  //   y = date.getFullYear(),
  //   m = date.getMonth();

  // console.log(new Date(y, m, 1));
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      supplier: expense?.supplier || supplier?._id || undefined,
      title: expense?.title || "",
      amount: expense?.amount || "",
      category: expense?.category || "",
      incurred_on: expense ? date : "",
      notes: expense?.notes || "",
    },
    validationSchema: ExpenseSchema,
    onSubmit: (values) => {
      if (!expense) {
        api.post(`/api/expenses`, values).then((response) => {
          if (!response.error) {
            alert("New expense created");
            navigate("/expenses");
          } else {
            console.log(response);
            setError(`${response.error.error}`);
          }
        });
      } else {
        api.put(`/api/expenses/${expense._id}`, values).then((response) => {
          if (!response.error) {
            alert("Expense updated");
            navigate("/expenses");
          } else {
            console.log(response);
            setError(`${response.error.error}`);
          }
        });
      }
    },
  });

  return (
    <div className="page">
      <div className="header">
        <h1>CREATE AN EXPENSE</h1>
        <div className="layout-end">
          <Link className="btn" to="/expenses">
            Annuler
          </Link>
        </div>
      </div>
      <div className="main">
        <form onSubmit={formik.handleSubmit}>
          <div>{error}</div>
          <div>
            <label>
              {supplier && (
                <h1>{supplier?.firstName + " " + supplier?.lastName}</h1>
              )}
            </label>
          </div>
          <div>
            <label htmlFor="title">Title</label>
            <input
              id="title"
              name="title"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.title}
            />
            {formik.errors.title && formik.touched.title ? (
              <div>{formik.errors.title}</div>
            ) : null}
          </div>
          <div>
            <label htmlFor="amount">Amount</label>
            <input
              id="amount"
              name="amount"
              type="number"
              onChange={formik.handleChange}
              value={formik.values.amount}
            />
            {formik.errors.amount && formik.touched.amount ? (
              <div>{formik.errors.amount}</div>
            ) : null}
          </div>
          <div>
            <label htmlFor="incurred_on">Date </label>
            <DatePicker
              locale="fr"
              dateFormat="dd-MM-yyyy"
              selected={formik.values.incurred_on}
              onChange={(date) => formik.setFieldValue("incurred_on", date)}
            />
            {formik.errors.incurred_on && formik.touched.incurred_on ? (
              <div>{formik.errors.incurred_on}</div>
            ) : null}
          </div>
          <div>
            <label htmlFor="category">category</label>
            <input
              id="category"
              name="category"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.category}
            />
            {formik.errors.category && formik.touched.category ? (
              <div>{formik.errors.category}</div>
            ) : null}
          </div>
          <div>
            <label htmlFor="category">Notes</label>
            <textarea
              id="notes"
              name="notes"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.notes}
            />
            {formik.errors.notes && formik.touched.notes ? (
              <div>{formik.errors.notes}</div>
            ) : null}
          </div>
          <button type="submit">Enregistrer</button>
        </form>
      </div>
    </div>
  );
}
