import React from "react";
import AppLayout from "../../layouts/AppLayout";

export default function ReportsPage() {
  return (
    <AppLayout>
      <div>ReportsPage</div>;
    </AppLayout>
  );
}
