import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import CustomerFilter from "../../components/customer/CustomerFilter";
import InvoiceForm from "../../components/invoice/InvoiceForm";
import { useApi } from "../../core/contexts/ApiProvider";
import AppLayout from "../../layouts/AppLayout";

export default function InvoiceEditPage() {
  const api = useApi();
  const { id, customerId } = useParams();
  const [customer, setCustomer] = useState();

  useEffect(() => {
    if (customerId && customerId !== "0") {
      api.get(`/api/customers/${customerId}`).then((response) => {
        setCustomer(response);
      });
    } else {
      setCustomer();
    }
  }, [customerId]);

  return (
    <AppLayout>
      <div className="layout-start">
        <CustomerFilter />
        {customerId !== "0" ? (
          <InvoiceForm customer={customer} />
        ) : (
          <div id="error-page">
            <h1>Select a customer</h1>
            <p>Sorry, an unexpected error has occurred.</p>
            <p>
              <Link className="btn" to="/invoices">
                Retour
              </Link>
            </p>
          </div>
        )}
      </div>
    </AppLayout>
  );
}
