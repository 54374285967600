import { useContext } from "react";
import { FlashContext } from "../../contexts/FlashProvider";

export default function FlashMessage() {
  const { flashMessage, visible, hideFlash } = useContext(FlashContext);
  return (
    <div>
      {visible && (
        <div className={`alert alert-${flashMessage.type}`} role="alert">
          <span>{flashMessage.message}</span>
          <button
            type="button"
            className="btn-close"
            onClick={hideFlash}
          ></button>
        </div>
      )}
    </div>
  );
}
