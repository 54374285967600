import React, { useEffect, useState } from "react";
import { useApi } from "../core/contexts/ApiProvider";

export default function ExpenseOverview() {
  const api = useApi();
  const [expensePreview, setExpensePreview] = useState();

  useEffect(() => {
    (async () => {
      const response = await api.get(`/api/expenses/current/preview`);
      if (!response.error) {
        setExpensePreview(response);
      }
    })();
  }, []);

  return (
    <div>
      {expensePreview && (
        <div>
          {expensePreview?.month?.totalSpent}
          {expensePreview?.today?.totalSpent}
          {expensePreview?.yesterday?.totalSpent}
        </div>
      )}
    </div>
  );
}
