import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { upload } from "../../core/api/expense-api";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useApi } from "../../core/contexts/ApiProvider";
import AppLayout from "../../layouts/AppLayout";

const uploadSchema = Yup.object().shape({});

export default function ExpenseImportPage() {
  const api = useApi();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      excelFile: null,
    },
    validationSchema: uploadSchema,
    onSubmit: (values) => {
      if (values.excelFile) {
        let data = new FormData();
        values.excelFile && data.append("excelFile", values.excelFile);

        api.post(`/api/expenses/upload`, data).then((response) => {
          if (!response.error) {
            alert("Chargement de données effectuées");
            navigate("/expenses");
          } else {
            console.log(response);
            setError(`${response.error.error}`);
          }
        });
      } else {
        setError("choisir un fichier");
      }
    },
  });

  return (
    <AppLayout>
      <div className="page">
        <div className="header">
          <h1>Import your expense</h1>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div>{error}</div>
          <input
            id="excelFile"
            name="excelFile"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            type="file"
            onChange={(event) => {
              formik.setFieldValue("excelFile", event.currentTarget.files[0]);
            }}
          />

          <button type="submit">View data</button>
          <Link to="/expenses">Annuler</Link>
        </form>
      </div>
    </AppLayout>
  );
}
