import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Register from "../../components/Register";
import { useApi } from "../../core/contexts/ApiProvider";
import AppLayout from "../../layouts/AppLayout";

export default function UserEditPage() {
  const api = useApi();
  const { id } = useParams();
  const [user, setUser] = useState();

  useEffect(() => {
    if (id) {
      api.get(`/api/users/byid/${id}`).then((response) => {
        setUser(response);
      });
    }
  }, [id]);

  return (
    <AppLayout>
      <div className="container">
        <div className="row">
          <div className="d-flex justify-content-between">
            <h1>Modifier votre profile</h1>
          </div>
        </div>
        <div className="main">
          <Register user={user} />
        </div>
      </div>
    </AppLayout>
  );
}
