import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Profile from "../../components/Profile";
import { useApi } from "../../core/contexts/ApiProvider";
import AppLayout from "../../layouts/AppLayout";

export default function UserDetailsPage() {
  const api = useApi();
  const { id } = useParams();
  const [user, setUser] = useState();

  useEffect(() => {
    api.get(`/api/users/${id}`).then((response) => {
      setUser(response);
    });
  }, [id]);

  return (
    <AppLayout>
      <div>
        <h1>Profile</h1>
        <Profile user={user} />
      </div>
    </AppLayout>
  );
}
